/*
 * @Author: wang si
 * @Date: 2024-12-02 17:49:48
 * @LastEditTime: 2024-11-28 10:51:45
 * @LastEditors: wang si
 * @Description: 充值上币接口
 * @FilePath: /uexch_pc/src/api/rechrgecurrency.js
 */
import axios from '@/utils/request'

// 获取充值上币列表
const upCoinListUrl = '/account/upcoinList'
const upCoinList = async (params) => {
  const { data } = await axios.post(upCoinListUrl, params)
  return data.data
}

const needLoginUrl = []

export default {
  needLoginUrl,
  upCoinList
}
