/*
 * @Author: ken yang
 * @Date: 2022-09-01 09:10:19
 * @LastEditTime: 2023-08-31 17:54:09
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import trade from '@/view/trade/index.js'
import futures from '@/view/futures/index.js'
import mail from '@/view/mail/index.js'
import home from '@/view/home/index.js'
import financial from '@/view/financial/index.js'
import ieo from '@/view/ieo/index.js'
import notice from '@/view/notice/index.js'
import airdrop from '@/view/airdrop/index.js'
import quotes from '@/view/quotes/index.js'
import user from '@/view/user/index.js'
import help from '@/view/help/index.js'
import rebate from '@/view/rebate/index.js'
import activity from '@/view/activity/index.js'
import wallet from '@/view/wallet/index.js'
import psersonalCenter from '@/view/psersonalCenter/index.js'
import c2c from '@/view/c2c/index.js'
import AppFooter from '@/layout/appFooter/AppFooter.vue'
import TableModel from '@/components/table/TableModel.vue'
import channelVerify from '@/view/channelVerify/index.js'
import ffutures from '@/subProject/ffutures/view/ffutures/index.js'
import tokenList from '@/view/tokenList/index.js'
import rechargeCurrency from '@/view/rechargecurrency/index.js'

const routerHistory = createWebHistory()

const routes = [
  { path: '', redirect: '/home' },
  ...home.routes,
  ...financial.routes,
  ...quotes.routes,
  ...ieo.routes,
  ...trade.routes,
  ...futures.routes,
  ...mail.routes,
  ...user.routes,
  ...help.routes,
  ...rebate.routes,
  ...activity.routes,
  ...wallet.routes,
  ...psersonalCenter.routes,
  ...c2c.routes,
  ...channelVerify.routes,
  ...ffutures.routes,
  ...notice.routes,
  ...airdrop.routes,
  ...tokenList.routes,
  ...rechargeCurrency.routes,
  {
    path: '/footer',
    component: AppFooter,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/home',
  },
  {
    path: '/TableModel',
    component: TableModel,
  },
]

const router = createRouter({
  history: routerHistory,
  routes,
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('error loading dynamically imported module')
  ) {
    if (!to?.fullPath) {
      window.location.reload()
    } else {
      window.location = to.fullPath
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  // const HTMLDom = document.querySelector('html')
  // if (to.path == '/trade' || !userStore.token) {
  //   HTMLDom.classList.add('hide-zendesk')
  // } else {
  //   HTMLDom.classList.remove('hide-zendesk')
  // }
  next()
})

export default router
