/*
 * @Author: wang si
 * @Date: 2024-11-20 20:13:54
 * @LastEditTime: 2023-05-31 18:03:50
 * @LastEditors: wang si
 * @Description:
 * @FilePath: /uexch_pc/src/view/depositcoins/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'
const RechargeCurrency = () => import('@/view/rechargecurrency/RechargeCurrency.vue')
const RechargeDetail = () => import('@/view/rechargecurrency/RechargeDetail.vue')
const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/rechargecurrency',
        component: RechargeCurrency,
        meta: { key: 'rechargecurrency' },
      },
      {
        path: '/rechargedetail',
        component: RechargeDetail,
        meta: { key: 'rechargedetail' },
      }
    ],
  }
]
const components = {}

export default {
  routes,
  components,
}
