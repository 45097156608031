<template>
  <div class="AppFooter">
    <Row class="w-1920 h-100b" x="around">
      <Column class="h-100b m-r-60 f-size-170">
        <img src="@/image/home/logo-footer.webp" class="w-158 h-42" style="object-fit: contain;">
        <!-- <span class="m-t-10 text-left text-nowrap">@ 2022 bitwexc.com</span> -->
        <Row class="apps w-100b">
          <IconSvg name="icon-IconTelegram_Footer" class="image c-hover-white"
            @click="open('https://t.me/bitwexcExchange')" />
          <!-- <IconSvg name="icon-IconTelegram_Footer" class="image c-hover-white" @click="open('https://t.me/bitwexc')" /> -->
          <!-- <IconSvg name="icon-IconTelegram_Footer" class="image c-hover-white" @click="open('https://t.me/Bitwexc_Globalcn')" /> -->
          <IconSvg name="icon-IconFacebook_Footer" class="image c-hover-white"
            @click="open('https://www.facebook.com/BitwexcExchange')" />
          <IconSvg name="icon-IconTwitter_Footer" class="image c-hover-white"
            @click="open('https://twitter.com/bitwexcexchange?s=21&t=--YPvZL9ynTwvhXY7fUT2Q')" />
          <IconSvg name="icon-IconMedium_Footer" class="image c-hover-white"
            @click="open('https://medium.com/@bitwexc05')" />
        </Row>
      </Column>
      <Row class="h-100b w-1400" x="sb">
        <Column class="link-box f-1 w-150">
          <div class="title">{{ $t('footer.about') }}</div>
          <span class="link h-22" @click="routerTo('/help/aboutUs')">{{ $t('footer.aboutUs') }}</span>
          <!-- <span v-to="'/bft'" class="link h-22">{{ $t('bft.aboutBFT') }}</span> -->
          <span class="link h-22" @click="routerTo('/register')">{{ $t('footer.joinus') }}</span>
          <span class="link h-22" @click="routerTo('/notice/announcements/1279')">{{ $t('footer.privacyPolicy')
            }}</span>
          <span class="link h-22" @click="routerTo('/notice/announcements/1284')">{{ $t('footer.termsofuse') }}</span>
          <span class="link h-22" @click="routerTo('/help/content?type=userAgreement')">{{ $t('footer.userAgreement')
            }}</span>
          <div class="link h-22" @click="routerTo('/help/content?type=responsibility')">{{ $t('footer.responsibility')
            }}</div>
          <div class="link h-22" @click="routerTo('/help/content?type=kyc')">{{ $t('footer.kyc') }}</div>
        </Column>
        <Column class="link-box f-1 w-150">
          <div class="title">{{ $t('footer.product') }}</div>
          <span class="link h-22" @click="routerTo('/trade')">{{ $t('header.spot')
            }}</span>
          <span class="link h-22" @click="routerTo('/launchpad')">Launchpad</span>
          <span class="link h-22" @click="routerTo('/financial')">{{ $t('header.financial') }}</span>
          <span class="link h-22" @click="routerTo('/airdrop')">{{ $t('public.airdrop') }}</span>
          <span class="link h-22" @click="routerTo('/sharepower')">{{ $t('footer.share') }}</span>
          <span class="link h-22" @click="routerTo('/')">{{ $t('footer.wallet') }}</span>
        </Column>
        <Column class="link-box f-1 w-150">
          <div class="title">{{ $t('footer.service') }}</div>
          <span class="link h-22" @click="routerTo('/')">{{ $t('footer.agent')
            }}</span>
          <span class="link h-22" @click="routerTo('/rebate', {
            requireLogin: true,
            redirect: '/rebate',
          })">{{ $t('wallet.invateRebate') }}</span>
          <span class="link h-22" @click="routerTo('/tokenList')">{{ $t('footer.tokenListing') }}</span>

          <span class="link " @click="routerTo('/')">{{ $t('footer.kol') }}</span>
          <span class="link h-22" @click="routerTo('/channelVerify')">BITWEXC Labs</span>
        </Column>

        <Column class="link-box f-1 w-150">
          <div class="title">{{ $t('footer.support') }}</div>
          <div class="link h-22">{{ $t('footer.download') }}
          </div>
          <div class="link h-22" @click="routerTo('/notice')">{{ $t('footer.bulletin') }}</div>
          <div class="link h-22" @click="routerTo('/channelVerify')">{{ $t('footer.channelVerify') }}</div>
          <div class="link h-22" @click="routerTo('/')">API</div>
          <div class="link h-22" @click="routerTo('/help/fees')">{{ $t('footer.fees') }}</div>
        </Column>
        <Column class="link-box f-1">
          <div class="title">{{ $t('footer.contactUs') }}</div>
          <div class="af-email h-23">{{ $t('footer.productfeedback') }}：support@bitwexc.com</div>
          <div class="m-b-17 h-23">{{ $t('footer.businesscooperation') }}：listing@bitwexc.com</div>
          <div class="m-b-17 h-23">Marketing：admin@bitwexc.com</div>
          <!-- <div class="m-b-17 h-23">{{ $t('footer.marketing') }}：admin@bitwexc.com</div> -->
        </Column>
      </Row>
    </Row>
  </div>
  <Row class="copyRigth">{{ $t('footer.copyRight') }}</Row>
</template>

<script setup>
const userStore = useUserStore()
const configStore = useConfigStore()
const route = useRoute()
const props = defineProps({
  price: {},
  amount: {},
  total: {},
  percent: {},
  type: {}
})

const open = (url) => {
  if (!/^http*/.test(url)) {
    url = 'http://' + url
  }
  window.open(url, '_blank')
}

const toLink = () => {
  const localeMap = {
    [LANGEN]: 'en-001',
    [LANGCN]: 'zh-cn',
    [LANGTW]: 'zh-tw'
  }
  // const url = `https://bitwexc.zendesk.com/hc/${localeMap[configStore.locale]}`
  // window.open(url)
}

const brandJump = () => {
  const localeMap = {
    [LANGEN]: 'en-001',
    [LANGCN]: 'zh-cn',
    [LANGTW]: 'zh-tw'
  }
  const localePath = {
    [LANGEN]: '/sections/7124911573135-Brand-News',
    [LANGCN]: '/sections/7124911573135-品牌动态',
    [LANGTW]: '/categories/8777742546191'
  }
  const url = `https://bitwexc.zendesk.com/hc/${localeMap[configStore.locale]}${localePath[configStore.locale]}`
  window.open(url)
}

const routerTo = (path) => {
  if (route.query.winId) {
    winMes.postParent('footerRouteTo', path)
  } else {
    router.push(path)
  }
}

onBeforeMount(() => { })
</script>

<style lang="scss">
@use 'sass:math';
@use 'sass:map';
@import '@/sass/var/index.scss';

.copyRigth {
  background-color: #16151a;
  text-align: center;
  width: 100%;
  color: white;
  justify-content: center !important;
  padding-bottom: 20px;
  font-size: 14px;
}

.AppFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
  padding: 55px 0 54px;
  box-sizing: border-box;
  background-color: #16151a;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;

  .language {
    width: 146px;
    height: 44px;
    padding-left: 16px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 4px;
    box-sizing: border-box;

    .icon {
      transition: all 0.3s;
    }

    &:hover {
      .icon {
        transform: rotateZ(-90deg);
      }
    }
  }

  .link-box {
    height: 100%;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);

    .title {
      font-size: 20px;
      color: #fff;
      margin-bottom: 20px;
      font-weight: 400;
    }

    .link {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
      cursor: pointer;
    }

    .af-email {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 14px;
    }

  }

  .apps {
    display: flex;
    margin-top: 20px;

    .image {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }
}
</style>
