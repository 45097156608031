/*
 * @Author: ken yang
 * @Date: 2023-08-28 09:49:58
 * @LastEditTime: 2023-09-11 15:04:49
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/url.js
 */

var wsUrl = import.meta.env.VITE_APP_WS_URL
var wsFuturesUrl = import.meta.env.VITE_APP_WS_FUTURES_URL

var serveUrl = import.meta.env.VITE_APP_BASE_URL
var h5Url = import.meta.env.VITE_APP_H5_URL

if (process.env.NODE_ENV === 'production') {
  var url = window.location.hostname
  wsUrl = `wss://${url}/zh_cn/`
  wsFuturesUrl = `wss://${url}/ws/zh_cn/`
  serveUrl = `https://${url}/api`
  h5Url = `https://${url}`
}

const bftLink = 'https://etherscan.io/token/0xedbf98724a86f92baefac101082c366e96f1e9d9'

export {
  wsUrl,
  wsFuturesUrl,
  serveUrl,
  h5Url,
  bftLink,
}
